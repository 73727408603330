<template>
  <div>
    <b-button
      type="submit"
      variant="primary"
      :to="`/groups/${$route.params.trackerid}`"
      size="md"
      class="mb-1"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">{{$t('Labels.Back')}}</span>
    </b-button>
    <b-card>
      <h3>
          {{group.name}}
      </h3>
      <hr>
      <h4 class="mt-1">
          {{$t("Messages.ChooseDeviceAdd")}}
      </h4>
      <b-form class="" @submit.prevent v-if="$store.state.session.AppActiveUser().userRole !== 'TP' && iAmAdmin">
        <validation-observer ref="trackerValidation">
          <b-row align-v="center">
            <b-col cols="12" md="4">
              <b-form-group :label="$tc('Labels.Trackers')" label-for="device">
                <validation-provider #default="{ errors }" name="device" rules="required">
                  <b-form-select name="device" :state="errors.length > 0 ? false:null" :options="trackers" v-model="tracker_uuid" value-field="uuid" text-field="name" :clearable="false">
                    <template #first>
                      <b-form-select-option :value="null" disabled>{{$tc('Labels.SelectTracker')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="2">
              <b-form-checkbox v-model="isAdministratorByTracker" name="check-button" switch inline class="">
                {{$t('Labels.Admin')}}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="2">
              <b-button variant="primary" @click="validationTracker" :title="$t('Labels.Add')" v-b-tooltip.hover="{ variant: 'primary', placement: 'left' }">
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
      <b-form class="" @submit.prevent v-if="iAmAdmin">
        <validation-observer ref="membersValidation">
          <b-row align-v="center">
            <b-col cols="12" md="4">
              <b-form-group :label="$t('Email')" label-for="email">
                <validation-provider #default="{ errors }" name="email" rules="email">
                  <b-form-input id="email" v-model="email" name="email" :placeholder="$t('Email')"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="2">
              <b-form-checkbox v-model="isAdministratorByEmail" name="check-button" switch inline class="">
                {{$t('Labels.Admin')}}
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="4">
              <b-button variant="primary" @click="validationEmail" :title="$t('Labels.Add')" v-b-tooltip.hover="{ variant: 'primary', placement: 'left' }" >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
      <b-row>
        <b-col cols="12" md="auto">
          <b-card header-tag="header">
            <template #header>
              <h4>
                {{$t("Labels.Members")}}
              </h4>
            </template>
            <b-list-group>
              <b-list-group-item v-for="(item, index) in members" :key="index" >
                <span class="align-middle">{{item.tracker.name}}</span>
                <b-badge variant="primary" class="ml-1 align-middle font-weight-bold font-small-2" v-if="!iAmAdmin && item.is_administrator">
                  {{$t('Labels.Admin')}}
                </b-badge>
                <b-button variant="danger" class="btn-icon rounded-circle float-right align-middle" @click="doDelete(item.tracker.tracker_uuid)" :title="$t('Labels.Delete')" v-b-tooltip.hover="{ variant: 'danger', placement: 'right' }" v-if="iAmAdmin" >
                  <feather-icon icon="TrashIcon" />
                </b-button>
                <b-form-checkbox v-model="item.is_administrator" name="check-button" switch inline class="float-right ml-1" @change="changePermissions(item.tracker.tracker_uuid, item.is_administrator)" v-if="iAmAdmin" >
                  <span>{{$t('Labels.Admin')}}</span>
                </b-form-checkbox>
                </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BBadge,
  BFormSelect,
  BFormSelectOption
} from 'bootstrap-vue'
import { required } from '@validations'
import request from '@/libs/request/index'

const DATA = {
  code: 200,
  data: {},
  msg: 'Ok'
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BBadge,
    BFormSelect,
    BFormSelectOption
  },
  data () {
    return {
      email: '',
      isAdministratorByTracker: false,
      isAdministratorByEmail: false,
      members: [],
      required,
      DATA,
      uuid: this.$route.params.uuid,
      trackerid: this.$route.params.trackerid,
      group: {},
      iAmAdmin: false,
      trackers: [],
      tracker_uuid: null
    }
  },
  methods: {
    async getTrackers () {
      this.trackers = []
      const params = {
        url: 'tracker/list',
        method: 'GET'
      }
      await request(params).then(response => {
        const allTrackers = response.data.data
        allTrackers.forEach(element => {
          let ban = false
          this.members.forEach(item => {
            if (element.uuid === item.tracker.tracker_uuid) {
              ban = true
            }
          })
          if (ban === false) {
            this.trackers.push(element)
          } else {
            ban = false
          }
        })
      }).catch(() => {
        this.trackers = []
      })
    },
    async getGroup () {
      const params = {
        url: `tracker_groups/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        this.group = data.data.data[0]
        this.members = data.data.data[0].user_group
        this.members.forEach(item => {
          if (item.tracker.tracker_uuid === this.trackerid) {
            this.iAmAdmin = item.is_administrator
          }
        })
        if (this.$store.state.session.AppActiveUser().userRole !== 'TP') {
          this.getTrackers()
        }
      })
    },
    async addMember () {
      const params = {
        url: `tracker_groups/${this.uuid}`,
        method: 'POST',
        customMessages: true,
        params: {
          email: this.email,
          is_administrator: this.isAdministratorByEmail
        }
      }
      await request(params).then(response => {
        this.email = ''
        this.$swal({
          title: this.$t('Messages.Success'),
          text: this.$t('Messages.RequestSend'),
          timer: 5000,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        })
      }).catch(error => {
        if (error.response.data.code === 409) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.AlreadyExist'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        } else if (error.response.data.code === 428) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.WaitingInfo'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        } else if (error.response.data.code === 404) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.UserNotFound'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        }
      })
    },
    validationEmail () {
      this.$refs.membersValidation.validate().then(success => {
        if (success) {
          this.addMember()
        }
      })
    },
    validationTracker () {
      this.$refs.trackerValidation.validate().then(success => {
        if (success) {
          this.addMemberByTracker()
        }
      })
    },
    async addMemberByTracker () {
      const params = {
        url: `tracker_groups/assign/${this.uuid}`,
        method: 'POST',
        customMessages: true,
        params: {
          tracker_uuid: this.tracker_uuid,
          is_administrator: this.isAdministratorByTracker
        }
      }
      await request(params).then(response => {
        this.$swal({
          title: this.$t('Messages.Success'),
          text: this.$t('Messages.AddSuccess'),
          timer: 5000,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        })
        const select = this.trackers.filter(item => {
          return item.uuid === this.tracker_uuid
        })
        this.members.push({
          is_administrator: this.isAdministratorByTracker,
          tracker: {
            tracker_uuid: select[0].uuid,
            name: select[0].name
          }
        })
        this.trackers = this.trackers.filter(item => {
          return item.uuid !== this.tracker_uuid
        })
        this.tracker_uuid = null
        this.$refs.trackerValidation.reset()
      }).catch(error => {
        if (error.response.data.code === 409) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.AlreadyExist'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        } else if (error.response.data.code === 428) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.FailMessage'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        }
      })
    },
    async doDelete (idTracker) {
      const params = {
        url: `tracker_groups/${idTracker}/${this.uuid}`,
        method: 'DELETE'
      }
      await request(params).then(response => {
        this.getTrackers()
        this.members = this.members.filter(item => item.tracker.tracker_uuid !== idTracker)
      })
    },
    async changePermissions (idTracker, status) {
      const params = {
        url: `tracker_groups/administrator/${idTracker}/${this.uuid}`,
        method: 'PUT',
        params: {
          is_administrator: status
        }
      }
      await request(params).catch(() => {
        this.members.forEach(element => {
          if (element.tracker.tracker_uuid === idTracker) {
            element.is_administrator = !element.is_administrator
          }
        })
      })
    }
  },
  created () {
    this.getGroup()
  }
}
</script>
